import { SetStateAction, createContext } from 'react';

export type CodeBlockLanguage = 'kotlin' | 'java' | 'json';

type SettingsContextType = {
  language: CodeBlockLanguage;
  setLanguage: React.Dispatch<SetStateAction<CodeBlockLanguage>>;
  showFullExample: boolean;
  setShowFullExample: React.Dispatch<SetStateAction<boolean>>;
};

const CodeBlockSettingsContext = createContext<SettingsContextType>({
  language: 'kotlin',

  setLanguage: () => {},
  showFullExample: false,

  setShowFullExample: () => {},
});

export default CodeBlockSettingsContext;
