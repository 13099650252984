import { Logo } from '@transferwise/components';
import { Menu as MenuIcon } from '@transferwise/icons';
import classNames from 'classnames';
import Link from 'next/link';

import { track } from '../../helpers/tracking';
import { Navigation } from '../../types';
import { Icon } from '../Icon';

import styles from './Header.module.scss';

export const Header = ({
  navigation,
  subnavigation,
}: {
  navigation?: Navigation;
  subnavigation?: Navigation;
}) => (
  <header className={styles.header}>
    <div className="site-container site-container--full-width">
      <div className="d-flex align-items-center">
        <Link
          href={navigation?.homeLink || '/'}
          className={styles.logo}
          onClick={() => {
            track(`Header click`, {
              site: navigation?.homeLink,
              link: navigation?.homeLink,
              title: 'Logo',
            });
          }}
        >
          <Logo className="d-block" />
        </Link>
      </div>
      {(!!navigation?.items.length || !!subnavigation?.items.length) && (
        <>
          <input
            className={`${styles.menuToggleCheckbox} hidden`}
            id="menu-toggle"
            type="checkbox"
          />
          {}
          <label className={styles.menuButton} htmlFor="menu-toggle">
            <MenuIcon size="24" />
          </label>
          <nav className={styles.navigation}>
            <ul className="list-unstyled m-a-0">
              {navigation?.items.map(({ title, link, icon, active }) => (
                <li key={title}>
                  <Link
                    href={link}
                    className={classNames(styles.navigationLink, 'text-no-decoration', {
                      [styles.navigationLinkActive]: active,
                    })}
                    onClick={() => {
                      track('Header click', { site: navigation.homeLink, link, title });
                      document.getElementById('menu-toggle')?.click();
                    }}
                  >
                    {icon ? <Icon icon={icon} size="24" /> : title}
                  </Link>
                </li>
              ))}
            </ul>
            {!!subnavigation?.items.length && (
              <ul className={`${styles.subNavigation} list-unstyled`}>
                {subnavigation?.title && (
                  <span className={styles.subNavigationTitle}>{subnavigation.title}</span>
                )}
                {subnavigation.items.map(({ title, link, active }) => (
                  <li key={title}>
                    <Link
                      href={link}
                      className={classNames(styles.navigationLink, 'text-no-decoration', {
                        [styles.navigationLinkActive]: active,
                      })}
                      onClick={() => {
                        track('Subnavigation click', { size: 'mobile', link, title });
                        document.getElementById('menu-toggle')?.click();
                      }}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </nav>
        </>
      )}
    </div>
  </header>
);
