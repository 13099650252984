import * as icons from '@transferwise/icons';
import { IconSize } from '@transferwise/icons/lib/types';
import React from 'react';

type Props = {
  icon: string;
  size?: IconSize;
  className?: string;
};

export const Icon = ({ icon, size = 16, className }: Props) => {
  const iconName = toCapitalisedCamelCase(icon);

  if (!Object.keys(icons).includes(iconName)) {
    throw new Error(
      `Invalid icon name. "${iconName}" doesn't exist in @transferwise/icons. Check https://transferwise.github.io/icons/`,
    );
  }

  const ICON = (icons as Record<string, React.FC<{ size: IconSize; className?: string }>>)[
    iconName
  ];

  return <ICON size={size} className={className} />;
};

function toCapitalisedCamelCase(value: string): string {
  return value.split('-').map(capitaliseFirstChar).join('');
}

function capitaliseFirstChar(value: string): string {
  return `${value[0]?.toUpperCase()}${value.slice(1)}`;
}

export function isValidIconName(name: string) {
  const iconName = toCapitalisedCamelCase(name);
  return Object.keys(icons).includes(iconName);
}
