import { Logo } from '@transferwise/components';
import classNames from 'classnames';
import Link from 'next/link';

import { track } from '../../helpers/tracking';
import { Navigation } from '../../types';

import styles from './Footer.module.scss';

type Props = {
  navigation?: Navigation;
};

export const Footer = ({ navigation }: Props) => (
  <footer className={styles.footer}>
    <div className={classNames('site-container site-container--full-width', styles.container)}>
      <div>
        <Link
          href={navigation?.homeLink || '/'}
          onClick={() => {
            track('Footer click', {
              site: navigation?.homeLink,
              link: navigation?.homeLink,
              title: 'Logo',
            });
          }}
        >
          <Logo />
        </Link>
      </div>
      <nav className={styles.navigation}>
        <ul className="list-unstyled m-a-0">
          {navigation?.items.map(({ title, link }) => (
            <li key={title}>
              <Link
                href={link}
                className={styles.navigationLink}
                onClick={() => track('Footer click', { site: navigation.homeLink, link, title })}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <div className={classNames('site-container site-container--full-width', styles.legal)}>
      <div className={classNames('small', styles.legalCopy)}>
        Wise Platform © {new Date().getFullYear()}
      </div>
    </div>
  </footer>
);
